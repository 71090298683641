<template>
  <v-container fluid>
    <v-card>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="d-flex align-center">
              <v-icon left> mdi-filter-variant </v-icon>
              Filtros
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-form @submit.prevent="aplicarFiltrosPagamentos">
              <v-row>
                <v-col sm="6" md="2">
                  <v-select
                    v-model="filtro.adquirente"
                    :items="itensAdquirente"
                    label="Adquirente *"
                    @change="changeValueTipoPagamento(filtro.adquirente)"
                  />
                </v-col>

                <v-col sm="6" md="3">
                  <v-text-field
                    v-model="filtro.codigoPagamento"
                    label="Código Pagamento"
                    :loading="buscando"
                    prepend-icon="mdi-numeric"
                  />
                </v-col>

                <v-col sm="12" md="7">
                  <v-select
                    v-model="filtro.empresasSelecionadas"
                    label="Empresa"
                    :loading="buscando"
                    :items="itensEmpresa"
                    multiple
                    clearable
                    prepend-icon="mdi-domain"
                  >
                    <template #prepend-item>
                      <v-list-item ripple @click="alterarSelecaoEmpresas">
                        <v-list-item-action>
                          <v-checkbox v-model="todasEmpresasSelecionadas" />
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Selecionar Tudo </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>

                    <template #selection="{ item, index }">
                      <span v-if="index === 3" class="grey--text text-caption">
                        (+{{ filtro.empresasSelecionadas.length - 3 }} outras)
                      </span>

                      <span
                        v-else-if="
                          (index < 2 && index === filtro.empresasSelecionadas.length - 1) ||
                          index == 2
                        "
                        class="mr-1"
                      >
                        {{ `${item.text}` }}
                      </span>

                      <span v-else-if="index < 2" class="mr-1"> {{ `${item.text},` }} </span>
                    </template>
                  </v-select>
                </v-col>

                <v-col sm="6" md="5">
                  <v-text-field
                    v-model="filtro.nomeCliente"
                    label="Nome Cliente"
                    :loading="buscando"
                    prepend-icon="mdi-account"
                  />
                </v-col>

                <v-col sm="6" md="3">
                  <v-text-field
                    v-model="filtro.valorPagamento"
                    label="Valor Pagamento"
                    :loading="buscando"
                    prepend-icon="mdi-currency-usd"
                    hint="Use '>' ou '<' para valores maiores/menores. (Ex: >149.99)"
                    autocomplete="off"
                  />
                </v-col>

                <v-col sm="6" md="2">
                  <v-menu
                    ref="menuDataInicial"
                    v-model="menuDataInicial"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="dataInicialFormatada"
                        label="Data Inicial"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :loading="buscando"
                        clearable
                        @click:clear="filtroDataInicial = ''"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="filtroDataInicial"
                      color="primary"
                      no-title
                      @input="menuDataInicial = false"
                    />
                  </v-menu>
                </v-col>

                <v-col sm="6" md="2">
                  <v-menu
                    ref="menuDataFinal"
                    v-model="menuDataFinal"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="dataFinalFormatada"
                        label="Data Final"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :loading="buscando"
                        clearable
                        @click:clear="filtroDataFinal = ''"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="filtroDataFinal"
                      color="primary"
                      no-title
                      @input="menuDataFinal = false"
                    />
                  </v-menu>
                </v-col>

                <v-col sm="6" md="2">
                  <v-menu
                    ref="menuDataInicialVenda"
                    v-model="menuDataInicialVenda"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="dataInicialVendaFormatada"
                        label="Data Inicial Venda"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :loading="buscando"
                        clearable
                        @click:clear="filtroDataInicialVenda = ''"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="filtroDataInicialVenda"
                      color="primary"
                      no-title
                      @input="menuDataInicialVenda = false"
                    />
                  </v-menu>
                </v-col>

                <v-col sm="6" md="2">
                  <v-menu
                    ref="menuDataFinalVenda"
                    v-model="menuDataFinalVenda"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="dataFinalVendaFormatada"
                        label="Data Final Venda"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :loading="buscando"
                        clearable
                        @click:clear="filtroDataFinalVenda = ''"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="filtroDataFinalVenda"
                      color="primary"
                      no-title
                      @input="menuDataFinalVenda = false"
                    />
                  </v-menu>
                </v-col>

                <v-col sm="6" md="8">
                  <v-select
                    v-model="filtro.tipoPagamento"
                    label="Tipo Pagamento"
                    :loading="buscando"
                    :items="itensTipoPagamento"
                    multiple
                    clearable
                    deletable-chips
                  />
                </v-col>
              </v-row>

              <v-row justify="end">
                <v-col cols="auto">
                  <v-btn class="ml-4" color="primary" outlined type="submit" :loading="buscando">
                    <v-icon left> mdi-magnify </v-icon>
                    Aplicar
                  </v-btn>
                  <v-btn
                    class="ml-4"
                    color="normal"
                    outlined
                    type="submit"
                    :loading="buscando"
                    @click.stop.prevent="limparSelecaoDeFiltros"
                  >
                    <v-icon left> mdi-autorenew </v-icon>
                    Limpar
                  </v-btn>
                  <v-btn
                    class="ml-4"
                    color="primary"
                    outlined
                    type="submit"
                    :disabled="buscandoXls"
                    :loading="buscandoXls"
                    @click="exportarPagamentosXls()"
                  >
                    Exportar Arquivo xls
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-card class="mt-2">
      <v-container fluid>
        <v-data-table
          :headers="headersPagamento"
          :items="pagamentos"
          :loading="buscando"
          :options.sync="optionsPagamentos"
          :server-items-length="totalElements"
          loading-text="Carregando os pagamentos..."
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [10, 20, 50, 100, 150, 200]
          }"
        >
          <template #top>
            <v-toolbar-title class="mb-4"> Pagamentos </v-toolbar-title>
          </template>

          <template #[`body.append`]="{ headers }">
            <tr>
              <td :colspan="headers.length">
                <div class="d-flex justify-space-around">
                  <strong>
                    Total Pagamentos:
                    {{
                      sumValorPagamento.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })
                    }}
                  </strong>
                  <strong>
                    Total Retirado da Conta:
                    {{
                      sumValorRetirada.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })
                    }}
                  </strong>
                  <strong>
                    Total Taxa:
                    {{
                      sumValorTaxa.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })
                    }}
                  </strong>
                  <strong>
                    Total Pagamentos Descontando Taxa:
                    {{
                      sumValorPagamentoSemTaxa.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      })
                    }}
                  </strong>
                </div>
              </td>
            </tr>
          </template>

          <template #[`item.valorPagamento`]="{ item }">
            {{
              item.valorPagamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            }}
          </template>

          <template #[`item.valorTaxa`]="{ item }">
            {{ item.valorTaxa.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
          </template>

          <template #[`item.codigoEventoTransacao`]="{ item }">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <strong v-bind="attrs" v-on="on">{{ item.codigoEventoTransacao }}</strong>
              </template>
              <span>{{
                buscaDescricaoPagamento(item.codigoEventoTransacao, item.adquirente)
              }}</span>
            </v-tooltip>
          </template>

          <template #[`item.dataHoraPagamento`]="{ item }">
            {{ formatDateTime(item.dataHoraPagamento) }}
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <SnackbarMessage
      :show-snack.sync="snackbar.showSnackParam"
      :snack-text="snackbar.snackTextParam"
      :snack-type="snackbar.snackTypeParam"
    />
  </v-container>
</template>

<script>
import PagamentoService from '@/services/pagamento/pagamento'
import EmpresaService from '@/services/organizacao/empresa'
import Snackbar from '@/models/ui/snackbar'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import { formatDate, formatDateTime, formataCpfCnpj } from '@/shareds/formatacoes'
import { buscaDescricaoPagamento, buscaTipoPagamentoFiltro } from '@/shareds/tipoPagamento'
import OrganizacaoService from '@/services/organizacao/organizacao'
import JSZip from 'jszip'

export default {
  components: {
    SnackbarMessage
  },

  data: () => ({
    totalElements: 0,
    optionsPagamentos: {
      page: 1,
      itensPerPage: 0,
      sortBy: []
    },
    pagamentos: [],
    sumValorPagamento: 0,
    sumValorRetirada: 0,
    sumValorTaxa: 0,
    sumValorPagamentoSemTaxa: 0,

    headersPagamento: [
      { text: 'Adquirente', value: 'adquirente', width: '7%' },
      { text: 'Código Pagamento', value: 'transactionId', width: '9%' },
      { text: 'Empresa', value: 'empresa.nome', width: '12%' },
      { text: 'Nome Cliente', value: 'nomeCliente', width: '15%' },
      { text: 'Email Cliente', value: 'emailCliente', width: '15%' },
      { text: 'Valor Liquido', value: 'valorLiquidoFormatado', width: '9%', sortable: false },
      { text: 'Valor Pagamento', value: 'valorPagamento', width: '9%' },
      { text: 'Valor Taxa', value: 'valorTaxa', width: '9%' },
      { text: 'Data Pagamento', value: 'dataHoraPagamento', width: '8%' },
      { text: 'Data Venda', value: 'dataVendaFormatada', width: '10%' },
      { text: 'Parcela', value: 'parcela' },
      { text: 'Tipo Pagamento', value: 'codigoEventoTransacao', width: '5%' }
    ],

    itensAdquirente: [
      { text: 'PayPal', value: 'PAYPAL' },
      { text: 'PagSeguro', value: 'PAGSEGURO' },
      { text: 'GetNet', value: 'GETNET' },
      { text: 'Rede', value: 'REDE' }
    ],

    itensTipoPagamento: [],
    itensEmpresa: [],

    formatDateTime: formatDateTime,
    buscaDescricaoPagamento: buscaDescricaoPagamento,
    snackbar: new Snackbar(),
    buscando: false,
    buscandoXls: false,
    filtro: {
      tipoPagamento: [],
      empresasSelecionadas: []
    },
    filtroDataInicial: '',
    filtroDataFinal: '',
    filtroDataInicialVenda: '',
    filtroDataFinalVenda: '',
    menu: false,
    menuDataInicial: false,
    menuDataFinal: false,
    dataInicialFormatada: '',
    dataFinalFormatada: '',
    menuDataInicialVenda: false,
    menuDataFinalVenda: false,
    dataInicialVendaFormatada: '',
    dataFinalVendaFormatada: '',
    pagamentoInfo: [],
    exportarPagamentos: []
  }),

  computed: {
    todasEmpresasSelecionadas: {
      get() {
        return this.filtro.empresasSelecionadas.length === this.itensEmpresa.length
      },
      set(value) {
        return value
      }
    }
  },

  watch: {
    filtroDataInicial() {
      this.dataInicialFormatada = formatDate(this.filtroDataInicial)
    },

    filtroDataFinal() {
      this.dataFinalFormatada = formatDate(this.filtroDataFinal)
    },

    filtroDataInicialVenda() {
      this.dataInicialVendaFormatada = formatDate(this.filtroDataInicialVenda)
    },

    filtroDataFinalVenda() {
      this.dataFinalVendaFormatada = formatDate(this.filtroDataFinalVenda)
    },

    optionsPagamentos: {
      handler() {
        if (this.filtro.adquirente) {
          this.buscarPagamentos()
        }
      },

      deep: true
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      await this.buscaAdquirentePadraoPelaOrganizacao()
      this.changeValueTipoPagamento(this.filtro.adquirente)
      this.buscarPagamentos()
    },

    aplicarFiltrosPagamentos() {
      Object.assign(this.optionsPagamentos, {
        page: 1
      })
      if (this.filtro.valorPagamento?.includes(' ')) {
        this.snackbar.snackTextParam =
          'Valor pagamento informado possui o formato inválido! (Use > ou < ' +
          ' para valores maiores/menores com o valor de pagamento sem utilizar espaços. (Ex: >149.99))'
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
      } else {
        this.buscarPagamentos()
      }
    },

    changeValueTipoPagamento(adquirente) {
      this.itensTipoPagamento = buscaTipoPagamentoFiltro(adquirente)
      this.buscarItensEmpresa(adquirente)
    },

    async buscarPagamentos() {
      this.buscando = true

      const paginacao = {
        page: this.optionsPagamentos.page - 1,
        size: this.optionsPagamentos.itemsPerPage,
        sort: this.optionsPagamentos.sortBy.map(
          (sort, indice) => `${sort},${this.optionsPagamentos.sortDesc[indice] ? 'DESC' : 'ASC'}`
        )[0]
      }

      try {
        const filtros = {
          adquirente: this.filtro.adquirente || undefined,
          codigoPagamento: this.filtro.codigoPagamento || undefined,
          empresasIds:
            this.filtro.empresasSelecionadas.length > 0
              ? this.filtro.empresasSelecionadas.join(',')
              : undefined,
          nomeCliente: this.filtro.nomeCliente || undefined,
          valorPagamento: this.filtro.valorPagamento
            ? this.filtro.valorPagamento.replaceAll('.', '').replaceAll(',', '.')
            : undefined,
          dataPagamentoInicial: this.filtroDataInicial || undefined,
          dataPagamentoFinal: this.filtroDataFinal || undefined,
          dataVendaInicial: this.filtroDataInicialVenda || undefined,
          dataVendaFinal: this.filtroDataFinalVenda || undefined,
          tipoPagamento:
            this.filtro.tipoPagamento.length > 0 ? this.filtro.tipoPagamento.join(',') : undefined
        }

        const response = await PagamentoService.buscarTudo({ ...filtros, ...paginacao })

        const pagamentosComValorLiquido = response.content.map((response) => {
          const taxa = response.valorTaxa || 0
          const valorLiquido = response.valorPagamento - taxa
          const valorLiquidoFormatado = 'R$ ' + valorLiquido.toFixed('2')

          const dataVenda = new Date(response.dataVenda)
          const dataVendaFormatada = `${dataVenda.getDate().toString().padStart(2, '0')}-${(
            dataVenda.getMonth() + 1
          )
            .toString()
            .padStart(2, '0')}-${dataVenda.getFullYear()}`

          return {
            ...response,
            valorLiquidoFormatado,
            dataVendaFormatada
          }
        })

        this.pagamentos = pagamentosComValorLiquido
        this.totalElements = response.totalElements

        this.buscarValores(filtros)
      } catch (error) {
        this.snackbar.snackTextParam = `${error.response.status} | ${
          error.response.data.message === undefined ? error.message : error.response.data.message
        }`
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
      } finally {
        this.buscando = false
      }
    },

    buscarValores(filtros) {
      PagamentoService.buscarValoresTotais(filtros)
        .then((response) => {
          this.sumValorPagamento = response.valorPagamento
          this.sumValorRetirada = response.valorRetirada
          this.sumValorTaxa = response.valorTaxa
          this.sumValorPagamentoSemTaxa = response.valorPagamentoSemTaxa
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os pagamentos.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoValores = false))
    },

    async limparSelecaoDeFiltros() {
      this.filtro = {
        tipoPagamento: [],
        adquirente: this.itensAdquirente[0].value,
        empresasSelecionadas: []
      }

      await this.buscaAdquirentePadraoPelaOrganizacao()

      this.filtroDataInicial = ''
      this.filtroDataFinal = ''
      this.filtroDataInicialVenda = ''
      this.filtroDataFinalVenda = ''

      this.optionsPagamentos = {
        page: 1,
        sortBy: [],
        itensPerPage: 0
      }
      this.changeValueTipoPagamento(this.filtro.adquirente)
      this.buscarPagamentos()
    },

    buscarItensEmpresa(adquirente) {
      this.itensEmpresa = []
      this.filtro.empresasSelecionadas = []

      EmpresaService.buscarTudo({ adquirente })
        .then((response) => {
          this.itensEmpresa = response.map((item) => ({
            text: `${formataCpfCnpj(item.documento)} - ${item.nome}`,
            value: item.id
          }))
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os empresas.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoEmpresa = false))
    },

    alterarSelecaoEmpresas() {
      this.$nextTick(() => {
        if (this.todasEmpresasSelecionadas) {
          this.filtro.empresasSelecionadas = []
        } else {
          this.filtro.empresasSelecionadas = this.itensEmpresa.slice().map((item) => item.value)
        }
      })
    },

    async buscaAdquirentePadraoPelaOrganizacao() {
      await OrganizacaoService.buscaAdquirentePadrao()
        .then((response) => {
          this.filtro.adquirente = response
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar o adquirente.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
    },

    exportarPagamentosXls() {
      const filtroParams = {
        adquirente: this.filtro.adquirente,
        codigoPagamento: this.filtro.codigoPagamento || undefined,
        empresasIds:
          this.filtro.empresasSelecionadas.length > 0
            ? this.filtro.empresasSelecionadas.join(',')
            : undefined,
        nomeCliente: this.filtro.nomeCliente || undefined,
        valorPagamento: this.filtro.valorPagamento
          ? this.filtro.valorPagamento.replaceAll('.', '').replaceAll(',', '.')
          : undefined,
        dataPagamentoInicial: this.filtroDataInicial || undefined,
        dataPagamentoFinal: this.filtroDataFinal || undefined,
        tipoPagamento:
          this.filtro.tipoPagamento.length > 0 ? this.filtro.tipoPagamento.join(',') : undefined
      }
      this.buscandoXls = true
      PagamentoService.export(filtroParams)
        .then((response) => {
          this.exportarPagamentos = response
          this.downloadzip()
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os pagamentos.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoValores = false))
    },

    downloadzip() {
      let zip = new JSZip()
      zip.file('pagamentos' + '.xls', this.exportarPagamentos)

      zip
        .generateAsync({
          type: 'base64'
        })
        .then(function (content) {
          var link = document.createElement('a')
          link.href = 'data:application/zip;base64,' + content
          link.download = 'pagamentos.zip'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .finally(() => (this.buscandoXls = false))
    }
  }
}
</script>

<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: calc(0.4rem + 1vmin);
}

::v-deep .v-data-table-header {
  vertical-align: top;
}
</style>
